@import './colors.scss';

.list {
  &.top-border {
    border-top: 1px solid $list-border-color;
  }

  .list-header {
    padding: 15px 20px;
    text-align: left;
    background-color: $list-header-background-color;
    color: $list-header-color;
    font-size: 14px;
    font-weight: bold;
  }

  .list-element {
    padding: 25px 20px;
    text-align: left;
    font-size: 17px;
    border-bottom: 1px solid $list-border-color;
    color: $list-text-color;
    background-color: transparent;

    &:hover {
      background-color: $hover-background-color;
    }

    &.with-icon-right {
      padding-right: 53px;
      position: relative;

      i {
        &.icon-right {
          right: 20px;
        }
      }
    }

    &.with-button {
      display: table;
      width: 100%;

      .text-holder,
      .button-holder {
        display: table-cell;
        vertical-align: middle;
      }

      .text-holder {
        text-align: left;
      }

      .button-holder {
        text-align: right;

        button {
          padding: 8px 20px;
          font-size: 15px;
        }
      }
    }

    i {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 30px;
      height: 30px;
      color: $list-icon-color;
    }
  }
}
