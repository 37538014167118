$background-color-white: #fff;
$background-color-black: #000;
$background-color-blue: #0089ff;
$hover-background-color: rgba(0, 0, 0, 0.05);
$footer-background-color: #fafafa;
$text-color-black: #1c1c1c;
$text-color-white: #fff;
$text-color-light-white: #fbfbfd;
$text-color-grey: #767676;
$text-color-green: #24db96;
$text-color-blue: #0089ff;
$text-color-red: #e82c37;
$list-header-background-color: #fafafa;
$list-header-color: #7c7c7c;
$list-border-color: #f2f2f2;
$list-text-color: rgba(0, 0, 0, 0.75);
$list-icon-color: #e1e1e1;
$call-button-default-background-color: #606060;
$call-button-default-text-color: #fff;
$search-background-color: #f4f4f4;
$search-text-color: #767676;
$feedback-tracking-code-color: #b6b6b6;
