@import '../styles/colors.scss';

.icon-button {
  display: inline-block;
  border: unset;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  background-color: transparent;

  &[disabled] {
    opacity: 0.5;
  }

  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: $hover-background-color;
  }

  &.smaller-icon {
    i {
      font-size: 20px;
    }
  }

  i {
    font-size: 30px;
    line-height: 31px;
  }
}

.buttons-container {
  margin: 10px 0;
  display: inline-block;
  text-align: center;
}

.button {
  margin: 0 5px;
  background-color: transparent;
  color: $text-color-black;
  font-size: 18px;
  font-weight: bold;
  border-radius: 24px;
  border: unset;
  padding: 10px 30px;
  cursor: pointer;

  &.blue {
    background-color: $background-color-blue;
    color: $text-color-light-white;
  }

  &.white {
    background-color: $background-color-white;
    color: $text-color-blue;
  }
}
