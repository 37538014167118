@import './colors.scss';

.card {
  background-color: $background-color-white;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  text-align: left;

  .card-header {
    padding: 15px;

    .card-actions {
      display: table;
      width: 100%;

      .action {
        display: table-cell;
        vertical-align: middle;

        &.left {
          text-align: left;
        }

        &.right {
          text-align: right;
        }

        .back-icon {
          margin-left: -9px;
        }
      }
    }

    .card-title {
      font-size: 32px;
      font-weight: 700;
      color: $text-color-black;
    }
  }

  .card-body {
    padding: 15px;
  }

  .card-footer {
    padding: 15px;
  }
}
