@import './colors.scss';

.form-control-container {
  margin-top: 15px;
  position: relative;
  font-size: 15px;
  color: $search-text-color;

  &.with-icon {
    i {
      position: absolute;
      height: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .form-control {
      padding-left: 30px;
      padding-right: 30px;
    }

    &.left {
      i {
        left: 10px;
      }
    }

    &.right {
      i {
        right: 10px;
      }
    }
  }

  .form-control {
    box-sizing: border-box;
    background-color: $search-background-color;
    border-radius: 12px;
    letter-spacing: 0;
    padding: 13px 20px;
    border: unset;
    width: 100%;

    &:focus {
      outline: none;
      border: unset;
    }
  }
}
