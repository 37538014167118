@import './assets/styles/colors.scss';
@import './assets/styles/card.scss';
@import './assets/styles/list.scss';
@import './assets/styles/buttons.scss';
@import './assets/styles/form-control.scss';

div {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: HelveticaNeue, Arial, sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: $text-color-blue;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &:active {
    color: inherit;
  }
}

.content-center {
  text-align: center;
}

.text-red {
  color: $text-color-red;
}

.page {
  background-color: $background-color-white;
  color: $text-color-black;
  text-align: center;

  &.black-background {
    background-color: $background-color-black;
    color: $text-color-white;
  }

  &.full-height {
    min-height: var(--window-height);
    height: auto;
    width: 100%;
    display: table;

    .page-wrapper {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .page-wrapper {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;

    &.with-logo {
      padding-top: 80px;
      padding-bottom: 80px;
      position: relative;
    }
  }

  .page-logo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 25px 20px;

    img {
      width: 80px;
      height: auto;
    }
  }

  .page-header {
    padding: 30px 20px;

    .header-icon {
      font-size: 30px;
      margin-bottom: 25px;
      cursor: pointer;
    }
  }

  .page-content {
    padding: 15px 20px;
  }

  .page-title {
    font-size: 24px;
    font-weight: bold;
  }

  .page-description {
    font-size: 17px;
  }
}

.loader-container {
  position: relative;
}
